// printBVTTablesToPDF.js

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import {
  buildBudgetTableData,
  buildCostsTableData,
  buildBustsPickupsTableData,
  buildSummaryLevel2TableData,
  buildSummaryLevel3TableData,
  buildSharedSavingsTableData,
} from "./bvtTableBuilders";

export function printBVTTablesToPDF2(projectName, budgetData) {
  const doc = new jsPDF({ orientation: "l", unit: "pt", format: "letter" });
  const now = new Date();
  const printDate = now.toLocaleDateString("en-US");

  let isFirstTable = true;

  function addAutoTable(title, head, body) {
    if (!head || !body) return; // skip if no data

    // If not first table, add a new page
    if (!isFirstTable) {
      doc.addPage();
    } else {
      // On first page, show project info
      doc.setFontSize(12);
      doc.text(`Project: ${projectName}`, 40, 40);
      doc.setFontSize(10);
      doc.text(`Printed on: ${printDate}`, 40, 55);
    }
    doc.setFontSize(14);

    const tableTitleY = isFirstTable ? 80 : 40;
    doc.text(title, 40, tableTitleY);

    const startY = tableTitleY + 10;

    autoTable(doc, {
      startY,
      theme: "grid",
      head,
      body,
      headStyles: { fillColor: "#093d61", textColor: "#FFFFFF" },
      styles: { fontSize: 8 },

      // 1) didParseCell -> do negative formatting + bold headers
      didParseCell: (hook) => {
        // Bold totals / section headers
        const rowData = hook.row.raw;
        if (rowData[0]?.isTotal || rowData[0]?.sectionHeader) {
          hook.cell.styles.fontStyle = "bold";
        }

        // Negative or non-negative formatting
        formatCellValue(hook, title);
      },

      // 2) willDrawCell -> double border
      willDrawCell: (hook) => {
        const { row, cell, doc } = hook;
        const rowData = row.raw;
        if (rowData[0]?.borderType === "double") {
          const lineY = cell.y;
          const leftX = cell.x;
          const rightX = cell.x + cell.width;

          doc.setLineWidth(0.7);
          doc.setDrawColor(0, 0, 0);
          doc.line(leftX, lineY, rightX, lineY);
          doc.line(leftX, lineY + 2, rightX, lineY + 2);
        }
      },
    });

    isFirstTable = false;
  }

  /**
   * Formats numeric cell values with:
   * - Always 2 decimals, with leading '$'
   * - Negative in "Costs Table" or "Total Busts/Pickups Table" => green parentheses
   * - Negative in all other => red minus sign
   */
  function formatCellValue(hookData, tableTitle) {
    const { cell } = hookData;
    if (typeof cell.raw?.content !== "string") return;

    const textVal = cell.raw.content;
    const numericVal = parseFloat(textVal.replace(/[^\d.-]/g, ""));
    if (isNaN(numericVal)) return;

    const isCostsOrBusts =
      tableTitle === "Costs Table" ||
      tableTitle === "Total Busts/Pickups Table";

    if (numericVal < 0) {
      // NEGATIVE
      const absVal = Math.abs(numericVal).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (isCostsOrBusts) {
        // ( $12.34 ) in green
        cell.styles.textColor = [0, 128, 0];
        cell.text = [`($${absVal})`];
      } else {
        // -$12.34 in red
        cell.styles.textColor = [255, 0, 0];
        cell.text = [`-$${absVal}`];
      }
    } else {
      // NON-NEGATIVE
      const posVal = numericVal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      // black text
      cell.text = [`$${posVal}`];
    }
  }

  // Build each table & add
  const { head: budgetHead, body: budgetBody } = buildBudgetTableData(budgetData);
  addAutoTable("Budget Table", budgetHead, budgetBody);

  const { head: costHead, body: costBody } = buildCostsTableData(budgetData);
  addAutoTable("Costs Table", costHead, costBody);

  const { head: bustsHead, body: bustsBody } = buildBustsPickupsTableData(budgetData);
  addAutoTable("Total Busts/Pickups Table", bustsHead, bustsBody);

  const { head: sl2Head, body: sl2Body } = buildSummaryLevel2TableData(budgetData);
  addAutoTable("Summary Level 2 (GC/GR Spend)", sl2Head, sl2Body);

  const { head: sl3Head, body: sl3Body } = buildSummaryLevel3TableData(budgetData);
  addAutoTable("Summary Level 3 (Owner Change Order Analysis)", sl3Head, sl3Body);

  // Shared Savings Table
  const { head: ssHead, body: ssBody } = buildSharedSavingsTableData(budgetData);
  addAutoTable("Shared Savings Table", ssHead, ssBody);

  // Save PDF
  doc.save(`BVT_Tables_${projectName}_${printDate}.pdf`);
}
