import store from '../../../store/store'; // Adjust the import path to your store
import { getHeaders } from './continerUtilityFunctions';

const getState = () => store.getState().global;


export const getCsrfToken = () => getState().csrfToken;
export const getHubAPIDomain = () => getState().hubDomain;
export const getApiKey = () => getState().apiKey;
export const getApiHeader = () => getState().apiHeader;
export const getLocalHeaders = () => getHeaders(getCsrfToken(), getApiHeader(), getApiKey());

const makeApiCall = async (url, payload, headers) => {
    const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
        credentials: 'include',
    });

    return response.json();
};

export const fetchProjectData = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_project_data/`, payload, getLocalHeaders());
};

export const fetchRevenueSpread = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_project_spread_data/`, payload, getLocalHeaders());
};

export const saveRevenueSpread = async (body) => {
    const payload = body;
    payload.csrf_token = getCsrfToken();
    return makeApiCall(`${getHubAPIDomain()}/phd/update_project_spread_data/`, payload, getLocalHeaders());
};

export const fetchProjectSettings = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_project_settings/`, payload, getLocalHeaders());
};

export const saveProjectSettings = async (body) => {
    const payload = body;
    payload.csrf_token = getCsrfToken();
    return makeApiCall(`${getHubAPIDomain()}/phd/save_project_settings/`, payload, getLocalHeaders());
};

export const fetchProjectInvoiceData = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_project_owner_invoice_data/`, payload, getLocalHeaders());
};

export const fetchProjectTeam = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_project_team/`, payload, getLocalHeaders());
};

export const fetchProjectBudgetData = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_project_budget_data/`, payload, getLocalHeaders());
};

export const fetchProjectBudgetDataBySnapshot = async (projectId, baselineSnapshotId, selectedPenultimateId, selectedCurrentId) => {
    if (!projectId) {
        throw new Error("Project ID is required.");
    }

    const payload = {
        project_id: projectId,
        selected_snapshots: [baselineSnapshotId, selectedPenultimateId, selectedCurrentId],
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(
        `${getHubAPIDomain()}/phd/get_project_budget_data/`,
        payload,
        getLocalHeaders()
    );
};

export const fetchOverUnderData = async (projectId, firstSnapshot_id, secondSnapshot_id) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
        snapshot1_id: firstSnapshot_id,
        snapshot2_id: secondSnapshot_id,
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_projected_over_under_details/`, payload, getLocalHeaders());
};

export const addOverUnderNote = async (projectId, cost_code, note) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
        cost_code: cost_code,
        note: note,
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/save_over_under_notes/`, payload, getLocalHeaders());
}

export const fetchAccessToProject = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/check_access_to_project/`, payload, getLocalHeaders());
};

export const fetchProjectsAndCompanies = async (companyFilterArray) => {
    const payload = {
        company_filter: companyFilterArray,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/get_projects_and_companies/`, payload, getLocalHeaders());
};

export const authCheck = async (user_uid) => {
    const payload = {
        user_uid: user_uid,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/auth_check/`, payload, getLocalHeaders());
};

export const projectSettingsDataPanelAPIs = async (projectId, end_point) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}${end_point}`, payload, getLocalHeaders());
}

export const triggerPHDSyncWithSalesforce = async (projectId) => {
    const payload = {
      project_id: projectId,
      csrf_token: getCsrfToken(),
    };
  
    return makeApiCall(
      `${getHubAPIDomain()}/phd/trigger_phd_sync_with_salesforce/`,
      payload,
      getLocalHeaders()
    );
  };

export const sendCostStatusEmail = async (projectId) => {
    const payload = {
        project_id: projectId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/send_cost_status_email/`, payload, getLocalHeaders());
}

export const commitMonthlyCostStatusSnapshot = async (projectId, snapshotId) => {
    const payload = {
        project_id: projectId,
        selected_snapshot: snapshotId,
        csrf_token: getCsrfToken(),
    };

    return makeApiCall(`${getHubAPIDomain()}/phd/commit_monthly_cost_status/`, payload, getLocalHeaders());
}
 
 