import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	Typography, MenuItem, Select, FormControl, InputLabel
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';
import { fetchOverUnderData } from '../../Containers/api';
import OverUnderTable from './OverUnderTable'; // Adjust the import path as needed
import { use } from 'react';

const useStyles = makeStyles({
});

const useReduxSelectors = () => {
	const projectId = useSelector((state) => state.project.selectedProjectId);

	return { projectId };
};


const OverUnderSummaryChart = ({ budgetData, isModal, selectedSnapshots, isLoadingSnapshots }) => {
	const { projectId  } = useReduxSelectors();

	const classes = useStyles();

	// API Setters for case 2
	const [topPickupsData, setTopPickupsData] = useState();
	const [topLossesData, setTopLossesData] = useState();
	const [apiError, setApiError] = useState(null);
	const [apiLoading, setApiLoading] = useState(false);
	const [firstSnapshot, setFirstSnapshot] = useState('');
	const [secondSnapshot, setSecondSnapshot] = useState('');
	
	// Setting Default snapshots for over/under details call -- Latest 2 snapshots
	useEffect(() => {
		if (!Array.isArray(selectedSnapshots) || selectedSnapshots.length < 2) return;
	
		const lastestSnap = selectedSnapshots.length - 1;
		const newFirst = selectedSnapshots[lastestSnap - 1];
		const newSecond = selectedSnapshots[lastestSnap];
	
		// Only set if not already set
		if (!firstSnapshot) {
			setFirstSnapshot(newFirst);
		}
	
		if (!secondSnapshot) {
			setSecondSnapshot(newSecond);
		}
	}, [selectedSnapshots]);


	// Fetch for Case #2 -- 2 Snapshots selected.
	useEffect(() => {
		if (firstSnapshot?.id && secondSnapshot?.id && projectId) {
			setApiLoading(true);
			setApiError(null);
	
			const fetchOverUnderDataAsync = async () => {
				try {
					// Call the API function from api.js
					const data = await fetchOverUnderData(
						projectId,
						firstSnapshot.id,
						secondSnapshot.id
					);
	
					if (data.error) {
						setApiError(data.error);
					} else {
						// Process the API response and set state
						setTopPickupsData(data.top_10 || []);
						setTopLossesData(data.bottom_10 || []);
					}
				} catch (error) {
					console.error('Error fetching over under details:', error);
					setApiError(error.toString());
				} finally {
					setApiLoading(false);
				}
			};	
			fetchOverUnderDataAsync();
		}
	}, [firstSnapshot, secondSnapshot]);

	const updateDataNotes = (cost_code, updatedNotes) => {
		topPickupsData.forEach((row) => {
			if (row.cost_code === cost_code) {
				row.notes = updatedNotes;
			}
		});
		topLossesData.forEach((row) => {
			if (row.cost_code === cost_code) {
				row.notes = updatedNotes;
			}
		});
	};

	if (projectId && (!budgetData || budgetData?.bvt_cost_code_mapping_available === false)) {
		return (
			<Typography className="titillium-web" color="error" style={{ textAlign: 'center', marginLeft: '25px', marginRight: '25px', marginTop: '20px', marginBottom: '10px', fontSize: '0.8rem' }}>
                {"Budget Attributes in your Procore job have NOT been assigned for this project."}
                {" If unsure how to assign, "}
                <a href="https://scribehow.com/shared/Budget_Attributes__AdUrTAt6SzG6F-rwzvGEdw" target="_blank" rel="noopener noreferrer">
                    click here.
                </a>
                <br /><br />
                {"If you recently added them in Procore, click "}
                <strong>Project Settings</strong>
                {" in the upper right > Select "}
                <strong>Data Control Panel</strong>
                {" > Click "}
                <strong>Sync Now</strong>
                {" in the 'Budget Data Sync' section > Click "}
                <strong>Close</strong>
                {". Wait 5 minutes then refresh the PHD."}
                <br /><br />
                {"Still having issues? Contact "}
                <a href="mailto:innovation@arcomurray.com">innovation@arcomurray.com</a>.
			</Typography>
		);
	}

	 // Use budgetData.over_under_summary when secondSnapshot is not selected
	const rows = { top_10: [], bottom_10: [] };

	// Filtered snapshots for second dropdown based on first snapshot - ensuring it's in the future
	const availableSnapshots = selectedSnapshots.filter((snapshot) => {
		return firstSnapshot ? new Date(snapshot.created_at) > new Date(firstSnapshot.created_at) : true;
	});

	const handleFirstSnapshotChange = (event) => {
		const selectedSnapshot1 = event.target.value;
		setFirstSnapshot(selectedSnapshot1);
		// Reset 2nd snapshot whenever the 1st changes
		setSecondSnapshot('');
	};

	const handleSecondSnapshotChange = (event) => {
		const selectedSnapshot2 = event.target.value;
		setSecondSnapshot(selectedSnapshot2);
	};

	return (
		<CardContent>
			{!projectId ? (
				<Typography style={{ textAlign: 'center' }} className="key-project-info-body">--</Typography>
			) : ((!topPickupsData || !topLossesData)|| (topPickupsData?.length === 0 && topLossesData?.length === 0)) ? (
				<Typography style={{ textAlign: 'center' }} className="key-project-info-body">Loading data...takes a bit, please hang on.</Typography>
			) : (isModal === false && isLoadingSnapshots) ? (
				// Case #1 - Full table shown in component -- POST request fired off of 2nd snapshot changes
				<OverUnderTable
					title=""
					data={[...topPickupsData, ...topLossesData]}
					maxHeight="250px"
					classes={classes}
				/>
			) : (
				// Case #2 - Modal is opened and table is split into top-10 lists
				<>
					<div style={{ display: 'flex', gap: '40px', marginBottom: '5px', marginLeft: '0px', marginRight: '0px', marginTop: '-10px' }}>
						<FormControl style={{ flex: 1, marginBottom: '5px' }}>
							<InputLabel>Snapshot A</InputLabel>
							<Select value={firstSnapshot} onChange={handleFirstSnapshotChange}>
								{selectedSnapshots.map((snapshot) => (
									<MenuItem key={snapshot.id} value={snapshot}>
										{snapshot.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl
							style={{ flex: 1 }}
							disabled={!firstSnapshot}
						>
							<InputLabel>Snapshot B</InputLabel>
							<Select value={secondSnapshot} onChange={handleSecondSnapshotChange}>
								{availableSnapshots.map((snapshot) => (
									<MenuItem key={snapshot.id} value={snapshot}>
										{snapshot.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>

					{apiLoading && (
						<Typography variant="body1" style={{ textAlign: 'center' }}>
							Loading data...
						</Typography>
					)}

					{apiError && (
						<Typography variant="body1" style={{ textAlign: 'center', color: 'red' }}>
							Error: {apiError}
						</Typography>
					)}
					{secondSnapshot && !apiLoading && !apiError && (
						<>
							<Typography variant="body1" style={{ textAlign: 'center' }}>
								{`Displaying Variance Between "${firstSnapshot.name}" and "${secondSnapshot.name}"`}
							</Typography>
							<div className={classes.container}>
								<OverUnderTable
									title="Top Pickups"
									data={topPickupsData}
									maxHeight="700px"
									classes={classes}
									showNotes={true}
									addNotes={true}
									updateDataNotes={updateDataNotes}
								/>
								<OverUnderTable
									title="Top Busts"
									data={topLossesData}
									maxHeight="700px"
									classes={classes}
									showNotes={true}
									addNotes={true}
									updateDataNotes={updateDataNotes}
								/>
							</div>
						</>
					)}
				</>
			)}
		</CardContent>
	);
};

export default OverUnderSummaryChart;

