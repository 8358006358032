import React, {useEffect, useState} from "react";
import { CardContent, Typography, Button, Modal, Box, Grid, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ca } from "date-fns/locale";
import { toast } from "react-hot-toast";
import { commitMonthlyCostStatusSnapshot, fetchProjectSettings } from "../../Containers/api";
import { setSettingsData } from "../../../../store/slices/projectSlice";

// Cases:
// Case 0: No projectID selected yet
// Case 1: Before the 21st of the month
// Case 2: Between: 21st and 26th: No snapshot selected
// Case 3: Between: 21st and 26th: Snapshot selected
// Case 4: After the 26th of the month

const useReduxSelectors = () => {
	const settingsData = useSelector((state) => state.project.settingsData);
	const isLoading = useSelector((state) => state.global.isLoading);
  const projectId = useSelector((state) => state.project.selectedProjectId);
  const projectData = useSelector((state) => state.project.projectData);

	return {
		settingsData,
		isLoading,
    projectId, 
    projectData
	};
};

const MonthlyCostStatusSnapshotBox = () => {
	const {
		settingsData,
		isLoading,
    projectId, 
    projectData
	} = useReduxSelectors();

  // Grab and deconstruct monthy_cost_status_data from settingsData
  const monthlyCostStatusData = settingsData?.monthly_cost_status_data || {};
  const {
    current_month_snapshots = [],
    current_month_snapshots_count = 0,
    selected_snapshot = null
  } = monthlyCostStatusData;

	const today = new Date();
	const current_month_string = today.toLocaleString('default', { month: 'long' });

	const currentMonth = today.getMonth();
	const targetDate = new Date(today.getFullYear(), currentMonth, 26, 0, 0, 0, 0);
	const countdown_days = Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
	const costStatusWindowActive = countdown_days >= 1 && countdown_days <= 5;

	const getInitialCaseNumber = () => {
    if (!projectId) {
      return 0;
    }
		if (!costStatusWindowActive) {
			return 1;
		}
    if (countdown_days > 0) {
      // If within window and snapshot selected set case 3, else case 2
        return selected_snapshot ? 3 : 2;
      }
		return 4;
	}	

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [caseNumber, setCaseNumber] = useState(0);
	const [isDisabled, setIsDisabled] = useState(true);
  const [selectedSnapshotId, setSelectedSnapshotId] = useState(selected_snapshot?.id || null);
  const [isConfirming, setIsConfirming] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setCaseNumber(getInitialCaseNumber());
  }, [projectId, countdown_days, selected_snapshot]);

  // Refresh Selected Snaphsot to display
  useEffect(() => {
    if (selected_snapshot?.id) {
      setSelectedSnapshotId(selected_snapshot.id);
    } else {
      setSelectedSnapshotId(null);
    }
  }, [selected_snapshot]);
  

  // If Case 2 or 3, we will enable the modal button
  useEffect(() => {
    if (caseNumber === 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(caseNumber !== 2 && caseNumber !== 3);
    }
  }, [caseNumber]);


	const handleOpenModal = () => {
		setIsModalOpen(true);
	};
	
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

  // Handle Selection of Snapshots
  const handleSnapshotChange = (e) => {
    setSelectedSnapshotId(e.target.value);
  };

  // Handle Commit of Snapshots
  const handleCommit = async () => {
    if (!projectId || !selectedSnapshotId) {
      toast.error("Missing projectId or snapshot selection.");
      return;
    }
    toast.success("Committing the selected snapshot...");
    handleCloseModal();
    try {
      // API Call Here
      const resData = await commitMonthlyCostStatusSnapshot(projectId, Number(selectedSnapshotId));
      if (resData.error) {
        throw new Error(resData.error);
      }
      // If successful:
      toast.success("Monthly Cost Status committed successfully!");

      // Update Project Data 
      const updatedProjectSettings = await fetchProjectSettings(projectId);

      // Dispatch to Redux to update settingsData
      if (updatedProjectSettings) {
        dispatch(setSettingsData(updatedProjectSettings));
        toast.success("Project settings data refreshed!");
      } else {
        toast.error("Updated settings data not received from API.");
      }
    } catch (error) {
    console.error("Error committing cost status:", error);
    toast.error("Commit failed. Please try again or contact support.");
    }
  };

  // Confirmation Step
  const handleInitialCommitClick = () => {
    setIsConfirming(true);
  };

  const handleFinalConfirm = () => {
    handleCommit();
    setIsConfirming(false);
  };

  const handleCancelConfirm = () => {
    setIsConfirming(false);
  };

	useEffect(() => {
		if (settingsData) {
			let monthly_cost_status_data = settingsData?.monthly_cost_status_data
			if (caseNumber === 2) {
				if (monthly_cost_status_data?.selected_snapshot) {
					setCaseNumber(3);
				}
			}
		}
	}, [settingsData]);

	return (
		<>
			<CardContent style={{ paddingTop: '20px', position: 'relative' }}>
        {caseNumber === 0 && (
          <Typography variant="body1" className="titillium-web" style={{ textAlign: "center", height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          --
        </Typography>
        )}
				{caseNumber === 1 && (
					<Typography variant="body1" className="titillium-web" style={{ textAlign: "center", marginBottom: "5px", height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						No action needed here for now. The Cost Status Report window opens on the <strong>21<sup>st</sup></strong> of {current_month_string}.
					</Typography>
				)}
				{[2,3].includes(caseNumber) && (
					<>
						<Typography variant="body1" className="titillium-web" style={{ textAlign: "center", marginBottom: "5px", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
							<span><strong>{countdown_days} day(s)</strong> until the Monthly Cost Status Report data is locked.<br />
							{caseNumber === 2 && (<span>PLEASE SELECT THE SNAPSHOT THAT YOU WANT TO REPORT THIS MONTH.</span>)}
							</span>
						</Typography>

						{caseNumber === 3 && selected_snapshot && (
              <div
                className="titillium-web"
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
              >
                <Typography className="titillium-web" style={{textAlign: "center"}}>
                  Current Selected Snapshot: 
                  <span style={{fontWeight: 'bold'}}> {selected_snapshot.name}</span>
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '5px'
                  }}
                >
                   <Typography className="titillium-web" style={{ fontSize: "14px", fontWeight: 'bold' }}>
                    Amount: $
                    {monthlyCostStatusData?.selected_snapshot_data?.Amount?.toLocaleString() || "N/A"}
                  </Typography>
                  <Typography className="titillium-web" style={{ fontSize: "12px", margin: '0 5px' }}>
                    |
                  </Typography>
                  <Typography className="titillium-web" style={{ fontSize: "14px", fontWeight: 'bold' }}>
                    Cost Status Profit: $
                    {monthlyCostStatusData?.selected_snapshot_data?.["Cost Status Profit"]?.toLocaleString() || "N/A"}
                  </Typography> 
                </div>
                <Typography className="titillium-web" style={{textAlign: "center", marginTop: '5px'}}>
                  The values above will be synced to Viewpoint and Salesforce after the 25th.
                </Typography>
              </div>
            )}
          </>
        )}
			</CardContent>
			{[2,3].includes(caseNumber) && (
				<>
					<div style={{ bottom: '0px', right: '16px', display:"flex" }}>
						{/* <Button
								variant="contained"
								color={isDisabled ? "default" : "primary"}
								disabled={isDisabled}
								onClick={handleOpenModal}
								style={{'marginLeft': 'auto'}}
						>
							Commit Monthly Cost Status
						</Button> */}
					</div>
					<Modal
						open={isModalOpen}
						onClose={handleCloseModal}
						aria-labelledby="snapshot-modal-title"
						aria-describedby="snapshot-modal-description"
					>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '50vw',
								bgcolor: 'background.paper',
								boxShadow: 24,
								borderRadius: 2,
							}}
						>
							<div style={{ padding: '25px', color: 'white', backgroundColor: '#093d61' }}>
									<Grid container alignItems="center" justifyContent="space-between">
											<Grid item xs={9}>
													<Typography
															className='titillium-web-base'
															style={{ fontSize: '25px', fontWeight: 'bolder', marginBottom: '5px' }}
													>
															Commit Monthly Cost Status
													</Typography>
                          <Typography className='titillium-web-base' style={{ fontSize: 'small' }}>
                        {isConfirming 
                          ? "Please confirm your selection."
                          : "Select your snapshot and commit."
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </div>

                <Box sx={{ p: 4 }}>
                  {isConfirming ? (
                    <>
                      <Typography classname="titillium-web" mb={2}>
                        Are you sure you want to commit the snapshot: <strong>{current_month_snapshots.find(s => s.id === Number(selectedSnapshotId))?.name}</strong>?
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            marginLeft: '16px',
                            marginTop: '5px'
                          }}
                        >
                          <Typography className="titillium-web">
                            Amount: $
                            {current_month_snapshots.find(s => s.id === Number(selectedSnapshotId))?.[`Amount`]?.toLocaleString() || "N/A"}
                          </Typography>
                          <Typography className="titillium-web">
                            Cost Status Profit: $
                            {current_month_snapshots.find(s => s.id === Number(selectedSnapshotId))?.[`Cost Status Profit`]?.toLocaleString() || "N/A"}
                          </Typography> 
                        </div>
                      </Typography>

                      <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleFinalConfirm}
                        >
                          Yes, Confirm This Snapshot
                        </Button>
                        &nbsp;&nbsp;
                        <Button variant="outlined" onClick={handleCancelConfirm}>
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    current_month_snapshots_count === 0 ? (
                      <Typography classname="titillium-web" mb={2}>
                        No snapshots currently available for this project. Please go create one in Procore.
                      </Typography>
                    ) : (
                      <>
                        <Typography classname="titillium-web" mb={2}>
                          Below are the snapshots for this month. Please select one to commit.
                        </Typography>

                        <RadioGroup
                          name="snapshotSelector"
                          value={String(selectedSnapshotId || "")}
                          onChange={handleSnapshotChange}
                          style={{ marginLeft: "16px" }}
                        >
                          {current_month_snapshots.map((snap) => (
                            <FormControlLabel
                              key={snap.id}
                              value={String(snap.id)}
                              control={
                                <Radio
                                  sx={{
                                    color: '#FF9800',
                                    '&.Mui-checked': { color: '#FF9800' },
                                  }}
                                />
                              }
                              label={snap.name}
                            />
                          ))}
                        </RadioGroup>

                        <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleInitialCommitClick}
                            disabled={!selectedSnapshotId}
                          >
                            Commit This Snapshot for Monthly Financials to Accounting
                          </Button>
                        </Box>
                      </>
                    )
                  )}
                </Box>
            </Box>
					</Modal>
				</>
			)}
		</>
	);
};

export default MonthlyCostStatusSnapshotBox;
