// bvtTableBuilders.js

// -----------------------
// SHARED UTILITY HELPERS
// -----------------------
function calculateVariance(a, b) {
    if (typeof a === "number" && typeof b === "number") {
      return b - a;
    }
    return null;
  }
  
  function calculateTotalsForType(contractSummary, type) {
    // e.g. contractSummary.contract_structure.Budget or .Cost
    const structure = contractSummary?.contract_structure?.[type] || {};
    const items = Object.keys(structure);
    let lumpSum = 0, gmp = 0;
    items.forEach((item) => {
      const val = structure[item] || 0;
      if (item.includes("LS")) {
        lumpSum += val;
      } else if (item.includes("GMP")) {
        gmp += val;
      }
    });
    return { lumpSum, gmp };
  }
  
  function accumulateAllowanceAndMarkups(baseline, penultimate, current, type) {
    let baselineAllowance = 0,
      penultimateAllowance = 0,
      currentAllowance = 0;
    let baselineMarkups = 0,
      penultimateMarkups = 0,
      currentMarkups = 0;
  
    const bObj = baseline.contract_structure?.[type] || {};
    const pObj = penultimate.contract_structure?.[type] || {};
    const cObj = current.contract_structure?.[type] || {};
    const allKeys = new Set([
      ...Object.keys(bObj),
      ...Object.keys(pObj),
      ...Object.keys(cObj),
    ]);
  
    allKeys.forEach((key) => {
      const bVal = bObj[key] || 0;
      const pVal = pObj[key] || 0;
      const cVal = cObj[key] || 0;
      if (key === "Allowance") {
        baselineAllowance += bVal;
        penultimateAllowance += pVal;
        currentAllowance += cVal;
      } else if (key === "Financial Markups") {
        baselineMarkups += bVal;
        penultimateMarkups += pVal;
        currentMarkups += cVal;
      }
    });
  
    return {
      baselineAllowance,
      penultimateAllowance,
      currentAllowance,
      baselineMarkups,
      penultimateMarkups,
      currentMarkups,
    };
  }
  
  function getOverUnderItems(baseline, penultimate, current) {
    const baseOU = baseline?.contract_structure?.["Over/Under"] || {};
    const penOU = penultimate?.contract_structure?.["Over/Under"] || {};
    const curOU = current?.contract_structure?.["Over/Under"] || {};
    const allKeys = new Set([...Object.keys(baseOU), ...Object.keys(penOU), ...Object.keys(curOU)]);
    return { baseOU, penOU, curOU, allKeys };
  }
  
  function getJTDCostsItems(baseline, penultimate, current) {
    const baseJTD = baseline?.contract_structure?.["JTD Costs"] || {};
    const penJTD = penultimate?.contract_structure?.["JTD Costs"] || {};
    const curJTD = current?.contract_structure?.["JTD Costs"] || {};
    return { baseJTD, penJTD, curJTD };
  }
  
  // ---------------------------------------------------------
  // 1) BUILD BUDGET TABLE
  // ---------------------------------------------------------
  export function buildBudgetTableData(budgetData) {
    if (
      !budgetData?.baseline_contract_summary ||
      !budgetData?.penultimate_contract_summary ||
      !budgetData?.current_contract_summary
    ) {
      return { head: [["No Budget Data"]], body: [] };
    }
    const { baseline_contract_summary: baseline, penultimate_contract_summary: penultimate, current_contract_summary: current } = budgetData;
  
    // The columns: Category | Baseline | Snapshot A | Snapshot B | A->B Var | Baseline->B Var
    const head = [[
      "Category",
      "Baseline",
      "Snapshot A",
      "Snapshot B",
      "Snapshot A to B Variance",
      "Baseline to Snapshot B Variance",
    ]];
    const body = [];
  
    // lumpsum & gmp totals
    const baseBudgetTotals = calculateTotalsForType(baseline, "Budget");
    const penBudgetTotals  = calculateTotalsForType(penultimate, "Budget");
    const currBudgetTotals = calculateTotalsForType(current, "Budget");
  
    // allowance & markups
    const {
      baselineAllowance,
      penultimateAllowance,
      currentAllowance,
      baselineMarkups,
      penultimateMarkups,
      currentMarkups,
    } = accumulateAllowanceAndMarkups(baseline, penultimate, current, "Budget");
  
    // Section row: "Lump Sum Budget"
    body.push([
      { content: "Lump Sum Budget", sectionHeader: true },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
    ]);
  
    // Each lumpsum budget item
    const currentBudgetItems = Object.keys(current.contract_structure.Budget || {});
    const lumpsumItems = currentBudgetItems.filter((item) => item.includes("LS"));
    lumpsumItems.forEach((item) => {
      const bVal = baseline.contract_structure.Budget[item] || 0;
      const pVal = penultimate.contract_structure.Budget[item] || 0;
      const cVal = current.contract_structure.Budget[item] || 0;
      const varPenToCur = calculateVariance(pVal, cVal);
      const varBaseToCur= calculateVariance(bVal, cVal);
  
      body.push([
        { content: item },
        { content: bVal.toString() },
        { content: pVal.toString() },
        { content: cVal.toString() },
        { content: varPenToCur?.toString() || "0" },
        { content: varBaseToCur?.toString() || "0" },
      ]);
    });
  
    // Lump Sum Budget Total
    body.push([
      { content: "Lump Sum Budget Total", isTotal: true },
      { content: baseBudgetTotals.lumpSum.toString() },
      { content: penBudgetTotals.lumpSum.toString() },
      { content: currBudgetTotals.lumpSum.toString() },
      {
        content: calculateVariance(penBudgetTotals.lumpSum, currBudgetTotals.lumpSum)?.toString() || "0",
      },
      {
        content: calculateVariance(baseBudgetTotals.lumpSum, currBudgetTotals.lumpSum)?.toString() || "0",
      },
    ]);
  
    // Section row: "GMP Budget"
    body.push([
      { content: "GMP Budget", sectionHeader: true },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
    ]);
    // each GMP item
    const gmpItems = currentBudgetItems.filter((item) => item.includes("GMP"));
    gmpItems.forEach((item) => {
      const bVal = baseline.contract_structure.Budget[item] || 0;
      const pVal = penultimate.contract_structure.Budget[item] || 0;
      const cVal = current.contract_structure.Budget[item] || 0;
      const varPenToCur = calculateVariance(pVal, cVal);
      const varBaseToCur= calculateVariance(bVal, cVal);
      body.push([
        { content: item },
        { content: bVal.toString() },
        { content: pVal.toString() },
        { content: cVal.toString() },
        { content: varPenToCur?.toString() || "0" },
        { content: varBaseToCur?.toString() || "0" },
      ]);
    });
  
    // GMP Budget Total
    body.push([
      { content: "GMP Budget Total", isTotal: true },
      { content: baseBudgetTotals.gmp.toString() },
      { content: penBudgetTotals.gmp.toString() },
      { content: currBudgetTotals.gmp.toString() },
      {
        content: calculateVariance(penBudgetTotals.gmp, currBudgetTotals.gmp)?.toString() || "0",
      },
      {
        content: calculateVariance(baseBudgetTotals.gmp, currBudgetTotals.gmp)?.toString() || "0",
      },
    ]);
  
    // Allowance Budget
    body.push([
      { content: "Allowance Budget" },
      { content: baselineAllowance.toString() },
      { content: penultimateAllowance.toString() },
      { content: currentAllowance.toString() },
      {
        content: calculateVariance(penultimateAllowance, currentAllowance)?.toString() || "0",
      },
      {
        content: calculateVariance(baselineAllowance, currentAllowance)?.toString() || "0",
      },
    ]);
  
    // Financial Markups Budget
    body.push([
      { content: "Financial Markups Budget" },
      { content: baselineMarkups.toString() },
      { content: penultimateMarkups.toString() },
      { content: currentMarkups.toString() },
      {
        content: calculateVariance(penultimateMarkups, currentMarkups)?.toString() || "0",
      },
      {
        content: calculateVariance(baselineMarkups, currentMarkups)?.toString() || "0",
      },
    ]);
  
    // Final row: "Total Budget Contract Values"
    const baseOverall = baseBudgetTotals.lumpSum + baseBudgetTotals.gmp + baselineAllowance + baselineMarkups;
    const penOverall  = penBudgetTotals.lumpSum + penBudgetTotals.gmp + penultimateAllowance + penultimateMarkups;
    const currOverall = currBudgetTotals.lumpSum + currBudgetTotals.gmp + currentAllowance + currentMarkups;
  
    body.push([
      { content: "Total Budget Contract Values", isTotal: true, borderType: "double" },
      { content: baseOverall.toString() },
      { content: penOverall.toString() },
      { content: currOverall.toString() },
      { content: calculateVariance(penOverall, currOverall)?.toString() || "0" },
      { content: calculateVariance(baseOverall, currOverall)?.toString() || "0" },
    ]);
  
    return { head, body };
  }
  
  // ---------------------------------------------------------
  // 2) BUILD COSTS TABLE
  // ---------------------------------------------------------
  export function buildCostsTableData(budgetData) {
    if (!budgetData?.baseline_contract_summary) {
      return { head: [["No Costs Data"]], body: [] };
    }
    const { baseline_contract_summary: baseline, penultimate_contract_summary: penultimate, current_contract_summary: current } = budgetData;
  
    const head = [[
      "Category",
      "Baseline",
      "Snapshot A",
      "Snapshot B",
      "Snapshot A to B Variance",
      "Baseline to Snapshot B Variance",
    ]];
    const body = [];
  
    const baseCostTotals = calculateTotalsForType(baseline, "Cost");
    const penCostTotals  = calculateTotalsForType(penultimate, "Cost");
    const currCostTotals = calculateTotalsForType(current, "Cost");
  
    const {
      baselineAllowance: baselineCostAllowanceTotal,
      penultimateAllowance: penultimateCostAllowanceTotal,
      currentAllowance: currentCostAllowanceTotal,
      baselineMarkups: baselineCostMarkupsTotal,
      penultimateMarkups: penultimateCostMarkupsTotal,
      currentMarkups: currentCostMarkupsTotal,
    } = accumulateAllowanceAndMarkups(baseline, penultimate, current, "Cost");
  
    // Lump Sum Costs
    body.push([
      { content: "Lump Sum Costs", sectionHeader: true },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
    ]);
  
    const currentCostItems = Object.keys(current.contract_structure.Cost || {});
    const lumpsumItems = currentCostItems.filter((item) => item.includes("LS"));
    lumpsumItems.forEach((item) => {
      const bVal = baseline.contract_structure.Cost[item] || 0;
      const pVal = penultimate.contract_structure.Cost[item] || 0;
      const cVal = current.contract_structure.Cost[item] || 0;
      const varPenToCur = calculateVariance(pVal, cVal);
      const varBaseToCur= calculateVariance(bVal, cVal);
  
      body.push([
        { content: item },
        { content: bVal.toString() },
        { content: pVal.toString() },
        { content: cVal.toString() },
        { content: varPenToCur?.toString() || "0" },
        { content: varBaseToCur?.toString() || "0" },
      ]);
    });
  
    // Lump Sum Costs Total
    body.push([
      { content: "Lump Sum Costs Total", isTotal: true },
      { content: baseCostTotals.lumpSum.toString() },
      { content: penCostTotals.lumpSum.toString() },
      { content: currCostTotals.lumpSum.toString() },
      {
        content: calculateVariance(penCostTotals.lumpSum, currCostTotals.lumpSum)?.toString() || "0",
      },
      {
        content: calculateVariance(baseCostTotals.lumpSum, currCostTotals.lumpSum)?.toString() || "0",
      },
    ]);
  
    // GMP Costs
    body.push([
      { content: "GMP Costs", sectionHeader: true },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
      { content: "" },
    ]);
    const gmpItems = currentCostItems.filter((i) => i.includes("GMP"));
    gmpItems.forEach((item) => {
      const bVal = baseline.contract_structure.Cost[item] || 0;
      const pVal = penultimate.contract_structure.Cost[item] || 0;
      const cVal = current.contract_structure.Cost[item] || 0;
      const varPenToCur = calculateVariance(pVal, cVal);
      const varBaseToCur= calculateVariance(bVal, cVal);
  
      body.push([
        { content: item },
        { content: bVal.toString() },
        { content: pVal.toString() },
        { content: cVal.toString() },
        { content: varPenToCur?.toString() || "0" },
        { content: varBaseToCur?.toString() || "0" },
      ]);
    });
  
    // GMP Costs Total
    body.push([
      { content: "GMP Costs Total", isTotal: true },
      { content: baseCostTotals.gmp.toString() },
      { content: penCostTotals.gmp.toString() },
      { content: currCostTotals.gmp.toString() },
      {
        content: calculateVariance(penCostTotals.gmp, currCostTotals.gmp)?.toString() || "0",
      },
      {
        content: calculateVariance(baseCostTotals.gmp, currCostTotals.gmp)?.toString() || "0",
      },
    ]);
  
    // Allowance Costs
    body.push([
      { content: "Allowance Costs" },
      { content: baselineCostAllowanceTotal.toString() },
      { content: penultimateCostAllowanceTotal.toString() },
      { content: currentCostAllowanceTotal.toString() },
      {
        content: calculateVariance(penultimateCostAllowanceTotal, currentCostAllowanceTotal)?.toString() || "0",
      },
      {
        content: calculateVariance(baselineCostAllowanceTotal, currentCostAllowanceTotal)?.toString() || "0",
      },
    ]);
  
    // Financial Markups Costs
    body.push([
      { content: "Financial Markups Costs" },
      { content: baselineCostMarkupsTotal.toString() },
      { content: penultimateCostMarkupsTotal.toString() },
      { content: currentCostMarkupsTotal.toString() },
      {
        content: calculateVariance(penultimateCostMarkupsTotal, currentCostMarkupsTotal)?.toString() || "0",
      },
      {
        content: calculateVariance(baselineCostMarkupsTotal, currentCostMarkupsTotal)?.toString() || "0",
      },
    ]);
  
    // Final row: "Total Cost Contract Values"
    const baseTotal = baseCostTotals.lumpSum + baseCostTotals.gmp + baselineCostAllowanceTotal + baselineCostMarkupsTotal;
    const penTotal = penCostTotals.lumpSum + penCostTotals.gmp + penultimateCostAllowanceTotal + penultimateCostMarkupsTotal;
    const currTotal= currCostTotals.lumpSum + currCostTotals.gmp + currentCostAllowanceTotal + currentCostMarkupsTotal;
  
    body.push([
      { content: "Total Cost Contract Values", isTotal: true, borderType: "double" },
      { content: baseTotal.toString() },
      { content: penTotal.toString() },
      { content: currTotal.toString() },
      {
        content: calculateVariance(penTotal, currTotal)?.toString() || "0",
      },
      {
        content: calculateVariance(baseTotal, currTotal)?.toString() || "0",
      },
    ]);
  
    return { head, body };
  }
  
  // ---------------------------------------------------------
  // 3) TOTAL BUSTS/PICKUPS
  // ---------------------------------------------------------
  export function buildBustsPickupsTableData(budgetData) {
    if (!budgetData?.baseline_contract_summary) {
      return { head: [["No Data"]], body: [] };
    }
    const { baseline_contract_summary: baseline, penultimate_contract_summary: penultimate, current_contract_summary: current } = budgetData;
  
    const head = [[
      "Category",
      "Baseline",
      "Snapshot A",
      "Snapshot B",
      "Snapshot A to B Variance",
      "Baseline to Snapshot B Variance",
    ]];
    const body = [];
  
    const { baseOU, penOU, curOU, allKeys } = getOverUnderItems(baseline, penultimate, current);
  
    let baselineTotal = 0,
      penTotal = 0,
      currTotal = 0;
  
    allKeys.forEach((item) => {
      const bVal = baseOU[item] || 0;
      const pVal = penOU[item]  || 0;
      const cVal = curOU[item]  || 0;
      baselineTotal += bVal;
      penTotal      += pVal;
      currTotal     += cVal;
  
      const varPenToCur = calculateVariance(pVal, cVal) || 0;
      const varBaseToCur= calculateVariance(bVal, cVal) || 0;
      body.push([
        { content: item },
        { content: bVal.toString() },
        { content: pVal.toString() },
        { content: cVal.toString() },
        { content: varPenToCur.toString() },
        { content: varBaseToCur.toString() },
      ]);
    });
  
    const varPenToCurr = calculateVariance(penTotal, currTotal) || 0;
    const varBaseToCurr= calculateVariance(baselineTotal, currTotal) || 0;
  
    // Final total row
    body.push([
      { content: "Total Busts/Pickups", isTotal: true, borderType: "double" },
      { content: baselineTotal.toString() },
      { content: penTotal.toString() },
      { content: currTotal.toString() },
      { content: varPenToCurr.toString() },
      { content: varBaseToCurr.toString() },
    ]);
  
    return { head, body };
  }
  
  // ---------------------------------------------------------
  // 4) SUMMARY LEVEL 2 (GC/GR Spend)
  // ---------------------------------------------------------
  export function buildSummaryLevel2TableData(budgetData) {
    if (!budgetData?.baseline_contract_summary) {
      return { head: [["No Data"]], body: [] };
    }
    const { baseline_contract_summary: baseline, penultimate_contract_summary: penultimate, current_contract_summary: current } = budgetData;
  
    const head = [[
      "Category",
      "Baseline",
      "Snapshot A",
      "Snapshot B",
      "Snapshot A to B Variance",
      "Total Estimated Budget Remaining",
    ]];
    const body = [];
  
    const { baseJTD, penJTD, curJTD } = getJTDCostsItems(baseline, penultimate, current);
    // Typically only "LS-GC/GR"
    const item = "LS-GC/GR";
    const bVal = baseJTD[item] || 0;
    const pVal = penJTD[item]  || 0;
    const cVal = curJTD[item]  || 0;
    const varPenToCur = calculateVariance(pVal, cVal);
    const varBaseToCur= calculateVariance(bVal, cVal);
  
    body.push([
      { content: item },
      { content: bVal.toString() },
      { content: pVal.toString() },
      { content: cVal.toString() },
      { content: varPenToCur?.toString() || "0" },
      { content: varBaseToCur?.toString() || "0" },
    ]);
  
    return { head, body };
  }
  
  // ---------------------------------------------------------
  // 5) SUMMARY LEVEL 3 (OWNER CHANGE ORDER ANALYSIS)
  // ---------------------------------------------------------
  export function buildSummaryLevel3TableData(budgetData) {
    if (!budgetData?.baseline_contract_summary) {
      return { head: [["No Data"]], body: [] };
    }
    const {
      baseline_contract_summary: baseline,
      penultimate_contract_summary: penultimate,
      current_contract_summary: current,
    } = budgetData;
  
    const head = [[
      "Category",
      "Total Overall ($)",
      "% of Budget",
      "Monthly Variance",
    ]];
    const body = [];
  
    // total budget for current
    const currentBudget = current.contract_structure?.Budget || {};
    const totalCurrentBudget = Object.keys(currentBudget)
      .reduce((acc, k) => acc + (currentBudget[k] || 0), 0);
  
    // 1) Change Events Revenue
    const ccr = current.change_event_revenue || 0;
    const penCcr = penultimate.change_event_revenue || 0;
    const ccrVar = ccr - penCcr;
    const ccrPct = totalCurrentBudget ? (ccr / totalCurrentBudget) * 100 : 0;
    body.push([
      { content: "Change Events Revenue" },
      { content: ccr.toString() },
      { content: ccrPct.toFixed(2) + "%" },
      { content: ccrVar.toString() },
    ]);
  
    // 2) Open PCOs
    const open = current.open_pcos || 0;
    const penOpen = penultimate.open_pcos || 0;
    const openVar = open - penOpen;
    const openPct = totalCurrentBudget ? (open / totalCurrentBudget) * 100 : 0;
    body.push([
      { content: "Open PCOs" },
      { content: open.toString() },
      { content: openPct.toFixed(2) + "%" },
      { content: openVar.toString() },
    ]);
  
    // 3) Approved PCOs & PCCOs
    const apc = current.approved_pcos_pccos || 0;
    const penApc = penultimate.approved_pcos_pccos || 0;
    const apcVar = apc - penApc;
    const apcPct = totalCurrentBudget ? (apc / totalCurrentBudget) * 100 : 0;
    body.push([
      { content: "Approved PCOs & PCCOs" },
      { content: apc.toString() },
      { content: apcPct.toFixed(2) + "%" },
      { content: apcVar.toString() },
    ]);
  
    // Totals row
    const grandCurr = ccr + open + apc;
    const grandPen  = penCcr + penOpen + penApc;
    const grandVar  = grandCurr - grandPen;
    const grandPct  = totalCurrentBudget ? (grandCurr / totalCurrentBudget) * 100 : 0;
    body.push([
      { content: "Total", isTotal: true, borderType: "double" },
      { content: grandCurr.toString() },
      { content: grandPct.toFixed(2) + "%" },
      { content: grandVar.toString() },
    ]);
  
    return { head, body };
  }
  
  // ---------------------------------------------------------
  // 6) SHARED SAVINGS TABLE
  // ---------------------------------------------------------
  export function buildSharedSavingsTableData(budgetData) {
    if (!budgetData?.shared_savings) {
      return {
        head: [["No Shared Savings Data"]],
        body: [],
      };
    }
    const head = [[
      "Category",
      "Potential Savings ($)",
      "Confirmed Savings ($)",
      "Variance",
    ]];
    const body = [];
  
    budgetData.shared_savings.forEach((row) => {
      body.push([
        { content: row.category || "N/A" },
        { content: (row.potential || 0).toString() },
        { content: (row.confirmed || 0).toString() },
        { content: (row.variance || 0).toString() },
      ]);
    });
  
    return { head, body };
  }
  