import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { formatDateShort } from "../utilityFunctions";

const WarrantyEndDate = ({ projectData }) => {
    const procoreWarrantyEndDate = projectData?.project_data?.warranty_end_date || projectData?.warranty_end_date;

return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center', fontSize: '.9rem'}} className="titillium-web" variant="body1">
                {formatDateShort(procoreWarrantyEndDate) || '--'}
            </Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default WarrantyEndDate;