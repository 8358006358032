import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TableContainer, Grid } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { printBVTTablesToPDF, generateInvoicePDF, generateExcel, formatDate } from '../utilityFunctions';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    copyButton: {
        margin: '10px 0',
    },
    excelButton: {
        backgroundColor: '#1e613b',
        color: 'white',
        margin: '10px 0px 10px 10px',
        '&:hover': {
            backgroundColor: '#1e613b',
        },
    }
});

const TablePopoutButton = forwardRef(({
    tableComponent: TableComponent,
    budgetData,
    invoiceData,
    title,
    source,
    selectedProject,
    selectedSnapshots,
    concurUrl,
    projectData,
    firstSnapshot,
    secondSnapshot,
    setFirstSnapshot,
    setSecondSnapshot,
    isLoadingSnapshots,
    overUnderData,
    projectId,
}, ref) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        if (selectedProject) {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    useImperativeHandle(ref, () => ({
        triggerClick: handleClickOpen,
    }));

    let tableDescription = '';
    let showButtons = null;
    let viewWidth = 'lg';
    let tableHeight = '60vh';
    let baselineSnapshot = null;
    let currentSnapshot = null;
    let penultimateSnapshot = null;

    switch (source) {
        case 'trade over/under':
            tableDescription = 'The tables below show total variance by cost code between the two selected snapshots.';
            viewWidth = 'xl';
            tableHeight = '70vh';
            break;
        case 'payment-invoicing':
            showButtons = 'payment-invoicing';
            tableDescription = "This table displays the project's owner invoicing history from Viewpoint.";
            viewWidth = 'xl';
            tableHeight = '60vh';
            break;
        case 'concur-table':
            viewWidth = 'xxl';
            tableHeight = '85vh';
            break;
        case 'keystyle-table':
            viewWidth = 'xxl';
            tableHeight = '65vh';
            break;
        case 'bvt-table':
            viewWidth = 'xl';
            tableHeight = '63vh';
            if (Array.isArray(selectedSnapshots) && selectedSnapshots.length > 0) {
                baselineSnapshot = selectedSnapshots[0] || null;
                currentSnapshot = selectedSnapshots[selectedSnapshots.length - 1] || null;
                penultimateSnapshot = selectedSnapshots.length > 1 ? selectedSnapshots[selectedSnapshots.length - 2] : null;
            }
            break;
        default:
            tableDescription = '';
            break;
    }

    const handleGeneratePDF = () => {
        if (source === 'bvt-table') {
            printBVTTablesToPDF(budgetData, selectedProject);
        } else if (source === 'payment-invoicing') {
            generateInvoicePDF(invoiceData, selectedProject);
        }
    };

    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <TableChartIcon />
            </IconButton>
            <Dialog open={openDialog} onClose={handleClose} maxWidth={viewWidth} fullWidth>
                <IconButton
                    onClick={handleClose}
                    style={{ color: '#000', position: 'absolute', top: '8px', right: '8px' }}
                >
                    {source === 'trade over/under' && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '8px' }}
                            href={`https://lessons.arcomurray.com/ll/new-lesson`}
                            target="_blank"
                            className="lessons-learned-button"
                        >
                            Log Lessons Learned
                            <AddCircleIcon style={{ paddingLeft: '2px', fontSize: 'medium', verticalAlign: 'middle' }} />
                        </Button>
                    )}
                    <CloseIcon style={{color: 'white'}}/>
                </IconButton>
                <div style={{ padding: '25px', color: 'white', backgroundColor: '#093d61' }} >
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={9}>
                            <Typography
                                className='titillium-web-base'
                                style={{ fontSize: '25px', fontWeight: 'bolder', marginBottom: '5px' }}
                            >
                                {title}
                            </Typography>
                            <Typography className='titillium-web-base' style={{ fontSize: 'small' }}>
                                {tableDescription}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} container justifyContent="flex-end" alignItems="center" spacing={1} gap="2px" style={{ paddingTop: '10px', marginTop: '10px' }}>
                            {showButtons && (
                                <>
                                    {showButtons === 'bvt-table' && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="pdf-button"
                                            onClick={handleGeneratePDF}
                                            style={{ marginRight: '8px' }}
                                        >
                                            Download PDF
                                        </Button>
                                    )}
                                    {showButtons === 'payment-invoicing' && (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="pdf-button"
                                                onClick={handleGeneratePDF}
                                                style={{ marginRight: '8px' }}
                                            >
                                                Download PDF
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className="excel-button"
                                                onClick={() => generateExcel(invoiceData)}
                                                style={{ marginRight: '8px' }}
                                            >
                                                Download Excel
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <DialogContent style={{ height: tableHeight, display: 'flex', flexDirection: 'column', paddingTop: '0px', paddingBottom: '0px'}}>
                    <TableContainer style={{ maxHeight: '90vh', overflow: 'auto' }}>
                        <TableComponent
                            budgetData={budgetData}
                            invoiceData={invoiceData}
                            selectedSnapshots={selectedSnapshots}
                            concurUrl={concurUrl}
                            isModal={true}
                            selectedProject={selectedProject}
                            firstSnapshot={firstSnapshot}
                            secondSnapshot={secondSnapshot}
                            setFirstSnapshot={setFirstSnapshot}
                            setSecondSnapshot={setSecondSnapshot}
                            isLoadingSnapshots={isLoadingSnapshots}
                            projectId={projectId}
                        />
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default TablePopoutButton;
