import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Typography } from '@material-ui/core';
import {formatDollar, formatDollarShort, formatDollarFull} from '../utilityFunctions'


const CostsVsBillingsChart = ({ invoiceData, budgetData }) => {
    // Extract the latest cumulative_paid from invoiceData
    const latestInvoice = invoiceData && invoiceData.length > 0 
        ? invoiceData.reduce((latest, current) => new Date(current.invoice_date) > new Date(latest.invoice_date) ? current : latest) 
        : null;
    
    const currentContractAmount = budgetData?.current_contract_summary?.revised_contract_value || 0;
    const cumulativePaid = latestInvoice ? latestInvoice.cumulative_paid : 0;
    const paymentToGo = currentContractAmount - cumulativePaid;
   

    // Extract the JTD costs from profitData
    const jtdCosts = budgetData?.current_contract_summary?.jtd_costs || 0;
    const costs = budgetData?.current_contract_summary?.total_estimated_costs || 0;
    const costsToGo = costs - jtdCosts
 
  // 2) Build Data for Bars
  const chartData = [
    {
      category: 'Billings',
      cumulativePaid: cumulativePaid,
      paymentToGo: paymentToGo,
      jtdCosts: 0,
      costsToGo: 0,
    },
    {
      category: 'Costs',
      cumulativePaid: 0,
      paymentToGo: 0,
      jtdCosts: jtdCosts,
      costsToGo: costsToGo,
    },
  ];

  // 3) Define Stacked Keys
  const keys = ['cumulativePaid', 'paymentToGo', 'jtdCosts', 'costsToGo'];

  // 4) Color Map Keys
  const colorByKey = {
    cumulativePaid: '#083d61',
    paymentToGo: '#3377a7',
    jtdCosts: '#54bef3',
    costsToGo: '#89d4f7',
  };

  const labelByKey = {
    cumulativePaid: 'Cumulative Paid',
    paymentToGo: 'Remaining Billings',
    jtdCosts: 'JTD Costs',
    costsToGo: 'Remaining Costs',
  }

  // 5) Render
  return (
    <div style={{ height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {cumulativePaid === 0 && jtdCosts === 0 ? (
        <Typography style={{ textAlign: 'center' }}>--</Typography>
      ) : (
        <ResponsiveBar
          data={chartData}
          keys={keys}
          indexBy="category"
          margin={{ top: 45, right: 50, bottom: 60, left: 120 }}
          padding={0.5}
          colors={({ id }) => colorByKey[id] || '#ccc'}
          names={({ id }) => labelByKey[id] || 'N/A'}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          borderRadius={1}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Amount ($)',
            legendPosition: 'middle',
            legendOffset: -85,
            format: value => formatDollarShort(value),
          }}
          // Avoid label overlap for small bars
          labelSkipWidth={42}
          labelSkipHeight={25}
          labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
          // Format the stacked segment label
          label={d => formatDollarShort(d.value)}
          // Add two legends for a 2x2 layout
          legends={[
            // First row: 'Cumulative Paid' & 'Remaining Billings'
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              translateX: 0,
              translateY: -50,
              itemsSpacing: 0,
              itemWidth: 140,
              itemHeight: 25,
              itemDirection: 'left-to-right',
              symbolSize: 13,
              fontSize: "20px",
              symbolShape: 'square',
              itemTextColor: '#000',
              data: [
                { id: 'cumulativePaid', label: 'Cumulative Paid', color: colorByKey.cumulativePaid },
                { id: 'paymentToGo', label: 'Remaining Billings', color: colorByKey.paymentToGo },
              ],
            },
            // Second row: 'JTD Costs' & 'Remaining Costs'
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              translateX: 0,
              translateY: -30,
              itemsSpacing: 0,
              itemWidth: 140,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              symbolSize: 13,
              symbolShape: 'square',
              itemTextColor: '#000',
              data: [
                { id: 'jtdCosts', label: 'JTD Costs', color: colorByKey.jtdCosts },
                { id: 'costsToGo', label: 'Remaining Costs', color: colorByKey.costsToGo },
              ],
            },
          ]}
          // Theme styling
          theme={{
            fontFamily: 'Titillium Web, sans-serif',
            legends: {
              text: {
                fontSize: 13,
                fontFamily: 'Titillium Web, sans-serif',
              }
            },
            labels: {
              text: {
                fontSize: 11,
                fill: '#000000',
                fontFamily: 'Titillium Web, sans-serif',
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fill: '#555555',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
              legend: {
                text: {
                  fontSize: 17,
                  fill: '#333333',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
            },
          }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          // Custom tooltip
          tooltip={({ id, value, indexValue }) => (
            <div
              style={{
                padding: '5px',
                background: '#333',
                color: '#fff',
                borderRadius: '3px',
                fontFamily: 'Titillium Web, sans-serif',
              }}
            >
              <strong>{`${indexValue}: ${labelByKey[id]}`}</strong>
              <br />
              Amount: {formatDollar(value)}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default CostsVsBillingsChart;
