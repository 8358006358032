import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

import {
  setSettingsData,
  setRevenueSpreadData,
} from "../../../../store/slices/projectSlice";
import { toastStyle } from "../../Containers/continerUtilityFunctions";

import BVTSettingsFields from "./BVTSettingsFieldsComponent";
import DataControlPanel from "./ProjectSettingsDataControlPanelComponent";

import { saveProjectSettings, saveRevenueSpread } from "../../Containers/api";
import RevenueSpreadSettingsFields from "./RevenueSpreadSettingsFieldsComponent";
import { use } from "react";
import { set } from "lodash";
import BudgetSnapshotSelector from "./BudgetSnapshotSelectorComponent";

const useReduxSelectors = () => {
  const settingsData = useSelector((state) => state.project.settingsData);
  const revenueSpreadData = useSelector(
    (state) => state.project.revenueSpreadData
  );
  const selectedProjectId = useSelector(
    (state) => state.project.selectedProjectId
  );

  return { settingsData, revenueSpreadData, selectedProjectId };
};

const ProjectSettingsModal = ({
  projectSnapshots,
  budgetData,
  selectedSnapshots,
  initialSnapshots,
  setProjectData,
  setTeamData,
  setBudgetData,
  setSelectedSnapshots,
}) => {
  const dispatch = useDispatch();
  const { settingsData, revenueSpreadData, selectedProjectId } =
    useReduxSelectors();

  const onSelectionChange = (newSnapshots) => {
    setSelectedSnapshots(newSnapshots);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [bvtFields, setBvtFields] = useState({
    contract_structure: "",
    job_has_deferred_tax: false,
    neg_insurance_percent: "",
    neg_fee_percent: "",
    shared_savings: false,
    shared_savings_split_percent_to_arco: "",
    max_shared_savings_amt: "",
    phd_sync: false,
  });
  const [revSpreadFields, setRevSpreadFields] = useState({
    sfdc_total_revenue: "",
    sfdc_off_the_books_revenue: "",
    unearned_revenue: "",
    sfdc_percent_revenue_this_year: "",
    sfdc_percent_revenue_second_year: "",
    sfdc_percent_revenue_third_year: "",
  });
  const [percentTotalError, setPercentTotalError] = useState(false);

  // Tab Toggle btwn three sections
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Populate bvtFields when settingsData changes
  useEffect(() => {
    if (settingsData?.other_meta_data) {
      setBvtFields(settingsData.other_meta_data);
    }
  }, [settingsData]);

  useEffect(() => {
    if (revenueSpreadData) {
      setRevSpreadFields(revenueSpreadData);
    }
  }, [revenueSpreadData]);

  // Function to close the modal AND trigger the PATCH request to update
  const handleSave = () => {
    // Prep fields for server
    const sanitizedBvtFields = {
      ...bvtFields, // spread operator brings in all other kv pairs
      job_has_deferred_tax: !!bvtFields.job_has_deferred_tax, // Dev fyi - !! forces var to convert into boolean
      shared_savings: !!bvtFields.shared_savings,
    };

    // Prepare the request body
    // REMOVING selected_snapshots for time being
    // const requestBody = {
    //   project_id: selectedProjectId,
    //   selected_snapshots: selectedSnapshots
    //     .filter((snapshot) => snapshot.selected)
    //     .map((snapshot) => snapshot.id),
    //   other_meta_data: sanitizedBvtFields,
    // };
    const requestBody = {
      project_id: selectedProjectId,
      other_meta_data: sanitizedBvtFields,
    };

    const saveProjectSettingsAsync = async () => {
      try {
        const resData = await saveProjectSettings(requestBody);
        if (resData.success) {
          // Now Saving the Rev spread
          const revSpreadRequestBody = {
            project_id: selectedProjectId,
            ...revSpreadFields,
          };
          const revSpreadResData = await saveRevenueSpread(
            revSpreadRequestBody
          );
          if (revSpreadResData.error) {
            toast.error(`Error: ${revSpreadResData.error}`, toastStyle());
          } else if (revSpreadResData.success) {
            dispatch(setSettingsData(requestBody));
            dispatch(setRevenueSpreadData(revSpreadRequestBody));
            toast.success(
              "Project Settings Saved Successfully. It takes about 2 minutes to refresh the calculations.",
              toastStyle()
            );
          }
        } else {
          console.error(
            "Error updating snapshots and settings:",
            resData.message
          );
        }
      } catch (error) {
        console.error("Error making POST request:", error);
      }
    };
    saveProjectSettingsAsync();
    setOpenDialog(false);
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        className="card-title"
        style={{
          color: "white",
          textAlign: "left",
          verticalAlign: "top",
          textDecoration: "underline",
          cursor: "pointer",
          fontSize: "1rem",
        }}
        onClick={() => setOpenDialog(true)}
      >
        Project Settings
      </Typography>
      <IconButton
        onClick={() => setOpenDialog(true)}
        style={{ textAlign: "right" }}
      >
        <SettingsIcon style={{ color: "white" }} />
      </IconButton>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: {
            height: "80vh",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle
          className="titillium-web-base"
          style={{
            fontSize: "30px",
            fontWeight: "bolder",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#093d61",
            color: "white",
          }}
        >
          Project Settings
          <IconButton
            onClick={() => {
              setOpenDialog(false);
            }}
            style={{ color: "#000" }}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </DialogTitle>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Budget Variance Settings" />
          <Tab label="Revenue Spread Data" />
          <Tab label="Budget Snapshot Selector" />
          <Tab label="Data Control Panel" />
        </Tabs>

        {/* ============= Project Settings for BVT-related items ================*/}
        <DialogContent
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {/* ============= BVT SETTINGS COMPONENT ================*/}
          {selectedTab === 0 && (
            <>
              <DialogTitle>Budget Variance Settings</DialogTitle>
              <BVTSettingsFields
                bvtFields={bvtFields}
                setBvtFields={setBvtFields}
              />
            </>
          )}
          {/* ============= REVENUE SPREAD COMPONENT ================*/}
          {selectedTab === 1 && (
            <>
              <DialogTitle>Revenue Spread Data</DialogTitle>
              <RevenueSpreadSettingsFields
                revSpreadFields={revSpreadFields}
                setRevSpreadFields={setRevSpreadFields}
                percentTotalError={percentTotalError}
                setPercentTotalError={setPercentTotalError}
              />
            </>
          )}
          {/* ============= BUDGET SNAPSHOT SELECTOR ================*/}
          {selectedTab === 2 && (
            <>
              <DialogTitle>Budget Snapshot Selector</DialogTitle>
              <BudgetSnapshotSelector
                budgetData={budgetData}
                isModal={true}
                projectSnapshots={projectSnapshots}
                selectedSnapshots={selectedSnapshots}
                onSelectionChange={onSelectionChange}
                initialSnapshots={initialSnapshots}
              />
            </>
          )}
          {/* ============= REFRESH CONTROL COMPONENT ================*/}
          {selectedTab === 3 && (
            <DataControlPanel
              setTeamData={setTeamData}
              setBudgetData={setBudgetData}
              setProjectData={setProjectData}
              setSettingsData={setSettingsData}
              bvtFields={bvtFields}
              setBvtFields={setBvtFields}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            className="smaller-secondary-button"
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            className={
              percentTotalError
                ? "smaller-primary-button:disabled"
                : "smaller-primary-button"
            }
            disabled={percentTotalError}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectSettingsModal;
