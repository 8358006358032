import React, { useState } from "react";
import procoreLogo from '../../static/procore_logo.png';
import sfdcLogo from '../../static/sfdc-logo.png';
import hubDash from '../../static/hub_logo_arcoblue.png';
import scribeLogo from '../../static/scribeLogo2.svg'
import GradeIcon from '@mui/icons-material/Grade';
import EmailIcon from '@material-ui/icons/Email';
import { Link, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import toast from "react-hot-toast";
import { sendCostStatusEmail } from "../../Containers/api";

// Dev Note: Passing links with noreferrer to help with security. May need to get rid of this if we want ever want analytics on these.

// TODO: Verify Arco_id and hub

const ProjectExternalLinks = ({projectData, selectedProjectId}) => {
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);

    if (!projectData || !projectData.project_data || (!projectData.project_data.procore_id && !projectData.project_data.sfdc_id && !projectData.project_data.arco_id && !selectedProjectId)) {
        return null;
    }

    const procoreURL = `https://app.procore.com/projects/${projectData.project_data.procore_id}/budgeting`
    const salesforceURL = `https://arcomurray.lightning.force.com/lightning/r/Opportunity/${projectData.project_data.sfdc_id}/view`
    const hubURL = `https://hub.arcomurray.com/project/${selectedProjectId}/`
    const scribeURL = `https://https://scribehow.com/shared/Project_Health_Dashboard_PHD__QIjB8iH3Tsq8igtQYw0UgA.com/signin`
    const subRatingsURL = `https://hub.arcomurray.com/sub-ratings/${selectedProjectId}/`
    const summaryEmailURL = ``

    // Send Cost Status Summary Email - Coontrolled by Modal Confirmation Window Now
    const handleDialogOpen = (e) => {
        e.preventDefault(); 
        setShowConfirmModal(true);
      };
    
      const handleDialogClose = () => {
        setShowConfirmModal(false);
      };
    
      const handleConfirmSendEmail = async () => {
        // Close the dialog while we process the email
        setShowConfirmModal(false);
    
        try {
          await sendCostStatusEmail(selectedProjectId);
          toast.success("Cost Status Summary email sent successfully!", {
            className: "titillium-web",
          });
        } catch (error) {
          console.error("Error sending cost status email:", error);
          toast.error("Failed to send Cost Status Summary email.", {
            className: "titillium-web",
          });
        }
      };

    return (
        <div className='project-icons-container control-panel' style={{verticalAlign: 'middle'}}>
            <Tooltip title="Open Project Budget in Procore" arrow>
                <Link id="link-1" href={procoreURL} target="_blank" rel="noopener noreferrer" className="control-button">
                        <img src={procoreLogo} className="project-link-icon" alt="Procore" />
                </Link>
            </Tooltip>
            <Tooltip title="Open Project in Salesforce" arrow>
                <Link id="link-2" href={salesforceURL} target="_blank" rel="noopener noreferrer" variant="body2" className="control-button">
                    <img src={sfdcLogo} className="project-link-icon" alt="Salesforce" title="Open in Salesforce"/>
                </Link>
            </Tooltip>
            <Tooltip title="Open Project in ARCO Hub" arrow>
                <Link id="link-3" href={hubURL} target="_blank" rel="noopener noreferrer" variant="body2" className="control-button">
                    <img src={hubDash} className="project-link-icon" alt="Hub Dash" title="Open Hub Project Details"/>
                </Link> 
            </Tooltip>
            <Tooltip title="Open Sub Ratings" arrow>
                <Link id="link-4" href={subRatingsURL} target="_blank" rel="noopener noreferrer" variant="body2" className="control-button">
                    <GradeIcon style={{fontSize: 'large', color: '#049be5'}} title="Rate Subs" />
                </Link>
            </Tooltip>
            <Tooltip title="Send Cost Status Summary Email" arrow>
                <Link
                    id="link-5"
                    href={summaryEmailURL}
                    target="_blank"
                    onClick={handleDialogOpen}
                    className="control-button"
                >
                    <EmailIcon style={{ fontSize: 'large', color: '#049be5' }} title="Cost Status Summary Email" />
                </Link>
            </Tooltip>

        <Dialog open={showConfirmModal} onClose={handleDialogClose}>
            <DialogTitle className="titillium-web">Send Cost Status Summary Email?</DialogTitle>
            <DialogContent className="titillium-web">
            On clicking on "Confirm", you will receive an email in few minutes with the financial summary for this project.<br />This email is meant to be just the starting point for your monthly Cost Status summary email. Please edit the email as you see fit before sending it out to your Ops Leaders.
            </DialogContent>
            <DialogActions>
            <Button className="titillium-web" onClick={handleDialogClose} color="info" variant="outlined">
                Cancel
            </Button>
            <Button className="titillium-web" onClick={handleConfirmSendEmail} color="primary" variant="contained">
                Confirm
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default ProjectExternalLinks;