import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tooltip,
} from "@material-ui/core";

import ProjectTypeCard from "./ProjectTypeComponent";
import SquareFootageCard from "./SquareFootageComponent";
import PriceSFCard from "./PricePerSFComponent";
import ProjectStageCardSmall from "./ProjectStatusSmallComponent";
import ProjectDurationCard from "./ProjectDurationComponent";
import ContractSignedDate from "./ContractSignedDateComponent";
import GroundBreakDate from "./GroundBreakDateComponent";
import SubstantialCompletionDate from "./SubstantialCompletionComponent";
import WarrantyStartDate from "./WarrantyStartDateComponent";
import WarrantyEndDate from "./WarrantyEndDateComponent";

const MasterProjectInfoCard = ({
  projectData,
  onStageColorChange,
  budgetData,
}) => {
  return (
    <CardContent
      style={{
        marginBottom: "-30px",
        marginTop: "-15px",
        marginLeft: "-20px",
        marginRight: "-20px",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Grid item xs={4}>
          <Typography
            variant="subtitle1"
            className="card-title"
            style={{ textAlign: "center" }}
          >
            Project Type
          </Typography>
          <ProjectTypeCard projectData={projectData} />
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="subtitle1"
            className="card-title"
            style={{ textAlign: "center" }}
          >
            Project Status
          </Typography>
          <ProjectStageCardSmall
            projectData={projectData}
            onStageColorChange={onStageColorChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="subtitle1"
            className="card-title"
            style={{ textAlign: "center", marginBottom: "0px" }}
          >
            Square Footage
          </Typography>
          <span className="ppsf-container">
            <SquareFootageCard projectData={projectData} />
            <PriceSFCard projectData={projectData} budgetData={budgetData} />
          </span>
        </Grid>
        {/* Dates Hidden -- Leaving Commented Out For Now */}
        {/* CONTRACT SIGNED */}
        {/* <Grid item xs={4}>
          <Tooltip
            title={
              <div className="warning-icon-tooltip-content">
                <Typography
                  style={{
                    fontSize: "1rem",
                    padding: "5px",
                    borderRadius: "3px",
                    fontFamily: "Titillium Web, sans-serif",
                  }}
                >
                  Contract Signed maps to the Start Date in Procore. This is the
                  date that the project's contract was signed.
                </Typography>
              </div>
            }
          >
            <span>
              <Typography
                variant="subtitle1"
                className="card-title"
                style={{ textAlign: "center" }}
              >
                Contract Signed
              </Typography>
              <ContractSignedDate projectData={projectData} />
            </span>
          </Tooltip>
        </Grid> */}
        {/* GROUNDBREAK */}
        {/* <Grid item xs={4}>
          <Tooltip
            title={
              <div className="warning-icon-tooltip-content">
                <Typography
                  style={{
                    fontSize: "1rem",
                    padding: "5px",
                    borderRadius: "3px",
                    fontFamily: "Titillium Web, sans-serif",
                  }}
                >
                  Groundbreak maps to the Actual Start Date in Procore. This is
                  the date that the project breaks ground.
                </Typography>
              </div>
            }
          >
            <span>
              <Typography
                variant="subtitle1"
                className="card-title"
                style={{ textAlign: "center" }}
              >
                Groundbreak
              </Typography>
              <GroundBreakDate projectData={projectData} />
            </span>
          </Tooltip>
        </Grid> */}
        {/* Substantial Completion*/}
        {/* <Grid item xs={4}>
          <Tooltip
            title={
              <div className="warning-icon-tooltip-content">
                <Typography
                  style={{
                    fontSize: "1rem",
                    padding: "5px",
                    borderRadius: "3px",
                    fontFamily: "Titillium Web, sans-serif",
                  }}
                >
                  Substantial Completion maps to the Completion Date in Procore.
                  This is the Substantial Completion Date.
                </Typography>
              </div>
            }
          >
            <span>
              <Typography
                variant="subtitle1"
                className="card-title"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                Substantial Completion
              </Typography>
              <SubstantialCompletionDate projectData={projectData} />
            </span>
          </Tooltip>
        </Grid> */}
        {/* Construction Duration */}
        {/* <Grid item xs={4}>
          <Tooltip
            title={
              <div className="warning-icon-tooltip-content">
                <Typography
                  style={{
                    fontSize: "1rem",
                    padding: "5px",
                    borderRadius: "3px",
                    fontFamily: "Titillium Web, sans-serif",
                  }}
                >
                  The time between Groundbreak and Substantial Completion.
                </Typography>
              </div>
            }
          >
            <span>
              <Typography
                variant="subtitle1"
                className="card-title"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                Construction Duration
              </Typography>
              <ProjectDurationCard projectData={projectData} />
            </span>
          </Tooltip>
        </Grid> */}
        {/* Warranty Start Date */}
        {/* <Grid item xs={4}>
          <Tooltip
            title={
              <div className="warning-icon-tooltip-content">
                <Typography
                  style={{
                    fontSize: "1rem",
                    padding: "5px",
                    borderRadius: "3px",
                    fontFamily: "Titillium Web, sans-serif",
                  }}
                >
                  COMING SOON! Warranty Start will map to the Warranty Start
                  Date in Procore.
                </Typography>
              </div>
            }
          >
            <span>
              <Typography
                variant="subtitle1"
                className="card-title"
                style={{ textAlign: "center" }}
              >
                Warranty Start
              </Typography>
              <WarrantyStartDate projectData={projectData} />
            </span>
          </Tooltip>
        </Grid> */}
        {/* Warranty End Date */}
        {/* <Grid item xs={4}>
          <Tooltip
            title={
              <div className="warning-icon-tooltip-content">
                <Typography
                  style={{
                    fontSize: "1rem",
                    padding: "5px",
                    borderRadius: "3px",
                    fontFamily: "Titillium Web, sans-serif",
                  }}
                >
                  COMING SOON! Warranty End will map to the Warranty End Date in
                  Procore.
                </Typography>
              </div>
            }
          >
            <span>
              <Typography
                variant="subtitle1"
                className="card-title"
                style={{ textAlign: "center" }}
              >
                Warranty End
              </Typography>
              <WarrantyEndDate projectData={projectData} />
            </span>
          </Tooltip>
        </Grid> */}
      </Grid>
    </CardContent>
  );
};

export default MasterProjectInfoCard;
