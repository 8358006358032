import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@material-ui/core';
import { formatDollar, formatDollarShort, formatDate } from '../utilityFunctions';
import { useSelector, useDispatch } from 'react-redux';

const CombinedContingencyProfitChart = ({ showAllData }) => {
    const dispatch = useDispatch();
    const budgetData = useSelector((state) => state.project.budgetData);

  if (!budgetData || !budgetData.contingency_data || budgetData.contingency_data.length === 0 ) {
    return <Typography style={{ textAlign: 'center' }}>--</Typography>;
  }
  
  // Create a map of profit data using snapshot_date for quick lookup
  const profitMap = new Map(
    budgetData.profit_data.map((p) => [p.snapshot_date, p.profit_percentage])
  );

  // Merge contingency and profit data based on snapshot_date
  let chartData = budgetData.contingency_data.map((item) => ({
    snapshot_date: item.snapshot_date ?? 'Missing Date',
    ls_contingency: item.ls_contingency ?? 0,
    gmp_contingency: item.gmp_contingency ?? 0,
    profit_percentage: profitMap.get(item.snapshot_date) ?? 0, // Fetch profit percentage using snapshot_date
  })).sort((a, b) => new Date(a.snapshot_date) - new Date(b.snapshot_date));

// Controlled through button in project_dashboard Component. Passing Prop in.
  if (!showAllData) {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    chartData = chartData.filter((item) => new Date(item.snapshot_date) >= sixMonthsAgo);
  }

  if (chartData.length === 0) {
    return <Typography style={{ textAlign: 'center' }}>No data available</Typography>;
  }

  const lineData = [{
    id: 'Profit Percentage',
    data: chartData.map(item => ({ x: item.snapshot_date, y: item.profit_percentage })),
  }];

// Used for Legend included in Responsive Line Chart (Profit % Section of Chart)
  const legendKeys = [
    { id: 'ls_contingency', label: 'LS Contingency', color: '#083d61' },
    { id: 'gmp_contingency', label: 'GMP Contingency', color: '#54BEF3' },
    { id: 'profit_percentage', label: 'Profit %', color: '#ffd700' },
];

  return (
    <div style={{ height: '400px', position: 'relative' }}>
      <div style={{ height: '100%', position: 'absolute', width: '100%' }}>
        <ResponsiveBar
          data={chartData}
          keys={['ls_contingency', 'gmp_contingency']}
          indexBy="snapshot_date"
          margin={{ top: 25, right: 85, bottom: 175, left: 80 }}
          padding={0.4}
          colors={['#083d61', '#54BEF3']}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Snapshot Date',
            legendPosition: 'middle',
            legendOffset: 70,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            legend: 'Contingency ($)',
            legendPosition: 'middle',
            legendOffset: -60,
            format: value => formatDollarShort(value),
          }}
          labelSkipWidth={50}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
          label={d => formatDollarShort(d.value)}
          tooltip={({ indexValue, data }) => (
            <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
              <strong>{formatDate(indexValue)}</strong>
              <br />
              LS Contingency: {formatDollar(data.ls_contingency ?? 0)}
              <br />
              GMP Contingency: {formatDollar(data.gmp_contingency ?? 0)}
              <br />
              Profit %: {(data.profit_percentage ?? 0).toFixed(2)}%
            </div>
          )}
          xScale={{ type: 'point' }}
          groupMode="stacked"
        />
      </div>

      <div style={{ height: '100%', position: 'absolute', width: '100%', pointerEvents: 'none' }}>
        <ResponsiveLine
          data={lineData}
          legendKeys={legendKeys}
          margin={{ top: 25, right: 85, bottom: 175, left: 80 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
          }}
          axisLeft={null}
          axisRight={{
            tickSize: 5,
            tickPadding: 5,
            legend: 'Profit %',
            legendPosition: 'middle',
            legendOffset: 45,
            format: value => `${value}%`,
          }}
          axisBottom={null}
          colors={['#ffd700']}
          enablePoints={true}
          enableGridX={false}
          enableGridY={false}
          pointSize={null}
          legends={[
            {
              data: [
                { id: 'GMP Contingency', label: 'GMP Contingency', color: '#54BEF3' },
                { id: 'LS Contingency', label: 'LS Contingency', color: '#083d61' },
                { id: 'Profit Percentage', label: 'Profit %', color: '#ffd700' },
              ],
              anchor: 'bottom', // Position below the chart
              direction: 'row',
              anchor: 'top',
              justify: false,
              translateX: 0,
              translateY: -30,
              itemsSpacing: 0,
              itemWidth: 120,
              itemHeight: 25,
              itemDirection: 'left-to-right',
              itemTextColor: '#333',
              symbolSize: 13,
              symbolShape: 'square', 
            },
        ]}
        />
      </div>
    </div>
  );
};

export default CombinedContingencyProfitChart;
