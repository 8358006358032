import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { CardContent, Typography } from '@mui/material';
import { formatDollar, formatDollarShort, formatDollarShorter } from '../utilityFunctions';

// Colors Used in Chart
const newColorPalette = ['#3377A7', '#54BEF3'];

const BudgetVarianceChart = ({ budgetData }) => {

    if (!budgetData || !budgetData?.current_contract_summary?.contract_structure || typeof budgetData?.current_contract_summary?.contract_structure !== 'object') {
        return (
            <CardContent style={{ height: '200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography className="key-project-info-body">--</Typography>
            </CardContent>
        );
    }

    const { Budget, Cost } = budgetData?.current_contract_summary?.contract_structure;

    if (!Budget || Object.keys(Budget).length === 0 || !Cost || Object.keys(Cost).length === 0) {
        return (
            <Typography className="titillium-web" color="error" style={{ textAlign: 'center', marginLeft: '25px', marginRight: '25px', marginTop: '20px', marginBottom: '10px', fontSize: '0.8rem' }}>
               {"Budget Attributes in your Procore job have NOT been assigned for this project."}
                {" If unsure how to assign, "}
                <a href="https://scribehow.com/shared/Budget_Attributes__AdUrTAt6SzG6F-rwzvGEdw" target="_blank" rel="noopener noreferrer">
                    click here.
                </a>
                <br /><br />
                {"If you recently added them in Procore, click "}
                <strong>Project Settings</strong>
                {" in the upper right > Select "}
                <strong>Data Control Panel</strong>
                {" > Click "}
                <strong>Sync Now</strong>
                {" in the 'Budget Data Sync' section > Click "}
                <strong>Close</strong>
                {". Wait 5 minutes then refresh the PHD."}
                <br /><br />
                {"Still having issues? Contact "}
                <a href="mailto:innovation@arcomurray.com">innovation@arcomurray.com</a>.
            </Typography>
        );
    }
    
    // Grab keys
    const revenueKeys = Object.keys(Budget);
    const costKeys = Object.keys(Cost)

    // Clean and combine Keys - Remove duplicates
    const cleanRevenueKeys = revenueKeys.filter(key => key && key.trim() !== '');
    const cleanCostKeys = costKeys.filter(key => key && key.trim() !== '');
    const allKeys = [...new Set([...revenueKeys, ...costKeys])];
    
    // Sort keys per direction
    const sortedKeys = allKeys.sort((a, b) => {
        const getPriority = (key) => key.includes("LS") ? 0 : key.includes("GMP") ? 1 : 2;
        return getPriority(a) - getPriority(b);
    }).reverse();

    const data = sortedKeys.map(key => ({
        category: key,
        Revenue: Budget[key] || 0,
        Cost: Cost[key] || 0,
    }));

    const xValues = data.flatMap(d => [d.Revenue, d.Cost]);
    const maxValue = Math.max(...xValues);
    let initialMinValue = Math.min(...xValues);

    // Ensure min value is not negative, set as 0 if it is to avoid negative x-axis
    if (initialMinValue < 0) {
        initialMinValue = 0;
    }
    const midValue = (maxValue + initialMinValue) / 2;

    // Sort Values using new min value
    const adjustedValues = xValues.map(val => (val < 0 ? 0 : val)).sort((a, b) => a - b);
    const q1 = adjustedValues[Math.floor(adjustedValues.length * 0.25)] || initialMinValue;
    const q3 = adjustedValues[Math.floor(adjustedValues.length * 0.75)] || maxValue;
    
    // Edge case -- Create x-axis tick values, minvalue replaced with 0 if original is negative
    let xTickValues = [ initialMinValue, midValue, maxValue];
    
    // Sort and remove duplicates for a clean axis
    xTickValues = [...new Set(xTickValues)].sort((a, b) => a - b);

    const formatLabel = (key) => {
        return key.replace(/ /g, '\n');
    };

    return (
        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 0 }}>
            <div style={{ height: '300px', width: '100%', maxWidth: '900px'}}>
                <ResponsiveBar
                    data={data}
                    keys={['Revenue', 'Cost']}
                    indexBy="category"
                    layout="horizontal"
                    margin={{ top: 25, right: 100, bottom: 80, left: 150 }}
                    padding={0.3}
                    colors={({ id }) => (id === 'Revenue' ? '#3377A7' : '#54BEF3')}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Amount ($)',
                        legendPosition: 'middle',
                        legendOffset: 50,
                        tickValues: xTickValues,
                        format: formatDollarShorter
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    groupMode='grouped'
                    borderRadius={2}
                    label={(d) => formatDollarShort(d.value)}
                    labelSkipWidth={100}
                    labelSkipHeight={100}
                    labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'top',
                            direction: 'row',
                            translateY: -30,
                            itemsSpacing: 10,
                            itemWidth: 90,
                            itemHeight: 30,
                            symbolSize: 15,
                        },
                    ]}
                    animate={true}
                    tooltip={({ id, indexValue, value }) => (
                        <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
                            <strong>{indexValue}</strong>
                            <br />
                            {id}: {formatDollar(value)}
                        </div>
                    )}
                    theme={{
                        labels: {
                          text: {
                            fontSize: 13.5,
                            fill: '#000000',
                            fontFamily: 'Titillium Web, sans-serif',
                          },
                        },
                        axis: {
                          ticks: {
                            text: {
                              fontSize: 12,
                              fill: '#555555',
                              fontWeight: 500,
                              fontFamily: 'Titillium Web, sans-serif',
                            },
                          },
                          legend: {
                            text: {
                              fontSize: 14,
                              fill: '#333333',
                              fontFamily: 'Titillium Web, sans-serif',
                            },
                          },
                        },
                      }}
                />
            </div>
        </CardContent>
    );
};

export default BudgetVarianceChart;